/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from '../locales'

export default {
  [LOCALES.ENGLISH]: {
    //buttons
    'sign-in': 'Sign In',
    signUp: 'Sign Up',
    accept: 'accept',
    reject: 'reject',
    logOut: 'Log Out',
    send: 'send',
    appeal: 'appeal',
    search: 'search',

    insteadLogin: 'I have an account.',

    language: 'TR',
    'brand-name': 'ANKARA UNIVERSITY',
    'new-account': 'create a new account',
    'forgot-password': 'i forgot my password',
    email: 'Email',
    password: 'Password',
    'my-profile': 'MY PROFILE',
    'add-product': 'ADD A PRODUCT',
    'add-a-product': 'Add a Product',
    'sign-out': 'SIGN OUT',
    type: 'Type',
    year: 'Year',
    product: 'Product',
    'Department of computer engineering': 'Department of computer engineering',
    edit: 'Edit',
    title: 'Title',
    'type of academic product': 'Type of Academic Report',
    author: 'Author',
    authors: 'Authors',
    productType: 'product type',
    downloadDocument: 'download document',
    link: 'link',
    productURL: 'product URL',
    description: 'description',
    publicationDate: 'Publication Date',

    'publication date': 'Publication Date',
    publisher: 'Publisher',
    document: 'Document',
    citations: 'Citations',
    'description of the academic product':
      'Description of the academic product',
    add: 'Add',
    cancel: 'Cancel',
    URL: 'URL',
    Name: 'Name',
    Surname: 'Surname',
    repeatedPassword: 'Repeat The Password',
    'Academic ID': 'Academic ID',
    'academic title list': 'Academic Title',
    chooseFaculty: 'Select Faculty',
    chooseDepartment: 'Select Department',

    // project types
    All: 'All',
    article: 'article',
    project: 'project',
    'conference paper': 'conference paper',
    'book chapter': 'book chapter',

    //academic titles
    'Prof. Dr.': 'Prof. Dr.',
    'Assoc. Prof. Dr.': 'Assoc. Prof. Dr.',
    'Assist. Prof. Dr.': 'Assist. Prof. Dr.',
    'Dr.': 'Dr.',
    'Inst.': 'Inst.',
    'Res. Assist.': 'Res. Assist.',

    //departments
    'computer engineering': 'computer engineering',

    //pages
    listOfAccounts: 'list of accounts',
    pendingAccounts: 'pending accounts',
    createReport: 'create a report',
    creatingReport: 'Creating A Report',
    searchNoun: 'search',
    downloadReport: 'Download A Report Of All Results',

    studyField: 'Field Of Study',
    unverifiedModalHeader: 'Your email has not been verified.',
    unverifiedModalBody:
      'You must verify your email for the admin to see your sign up request and approve it.',
    unverifiedModalFooter: 'Send a new verification link?',
    pendingModalHeader: 'Your account has not been approved by the admin yet.',
    pendingModalBody:
      'Your sign up request has been sent to the admin for approval.',
    pendingModalFooter: 'You have to wait for the approval.',
    rejectedModalHeader: 'Your account request has been rejected.',
    rejectedModalBody: 'Your can sign up again with correct information.',
    rejectedModalFooter: 'or you can send an appeal request.',
    successfullRegisterationHeader: 'Verify Your Account',
    successfullRegisterationBody:
      'A verification link has been sent to your email.',
    successfullRegisterationFooter:
      'Check your email for that link to verify your account.',
    successfullVerificationHeader: 'Your email has been verified.',
    successfullVerificationBody:
      'Your account has been sent to the admin for approval.',
    DateBefore: 'Publication Date To',
    DateAfter: 'Publication Date From',
    moreThanCitations: 'Citations More Than',
    lessThanCitations: 'Citations less Than',
    delete: 'Delete',
    deleteModalHeader: 'Delete Academic Product',
    deleteModalBody: 'Are you sure you want to delete this academic product?',
    deleteModalFooter: 'This operation cannot be undone!',
    ORCİd: 'ORCİd',
    indexType: 'Index Type',
  },
}
