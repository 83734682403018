const Footer = () => {
  return (
    <footer className="arved-footer text-center">
      <p className="m-auto">
        Araştırma Verilerin Değerlendirme Sistemi - Research Data Evaluation
        System
      </p>
    </footer>
  )
}

export default Footer
