/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from '../locales'

export default {
  [LOCALES.TURKISH]: {
    //buttons

    'sign-in': 'Giriş Yap',
    signUp: 'Üye Ol',
    accept: 'Onayla',
    reject: 'reddet',
    logOut: 'Çıkış Yap',
    send: 'gönder',
    appeal: 'itiraz et',
    search: 'ara',

    insteadLogin: 'bir hesabım var.',

    language: 'EN',
    'brand-name': 'ANKARA ÜNİVERSİTESİ',
    'new-account': 'Yeni Bir hesap oluştur',
    'forgot-password': 'Şifremi Unuttum',
    email: 'E-posta',
    password: 'Şifre',
    'my-profile': 'BENIM PROFILIM',
    'add-product': 'ÜRÜN EKLE',
    'add-a-product': 'Ürün Ekle',
    'sign-out': 'ÇIKIŞ YAP',
    type: 'Tip',
    year: 'Yıl',
    product: 'Ürün',
    'Department of computer engineering': 'Bilgisayar Mühendislik Bölümü',
    edit: 'Düzenle',
    title: 'Başlık',
    'type of academic product': 'Akademik Rapor Türü',
    author: 'Yazar',
    authors: 'Yazarlar',
    productType: 'ürün tipi',
    'publication date': 'Yayın Tarihi',
    publisher: 'Yayımcı',
    document: 'Belge',
    downloadDocument: 'belgeyi indir',
    link: 'bağlantı',
    productURL: 'ürün URL',
    citations: 'Alıntı',
    description: 'Açıklama',
    publicationDate: 'Yayın Tarihi',
    'description of the academic product': 'Akademik ürünün açıklaması',
    add: 'Ekle',
    cancel: 'İptal',
    URL: 'URL',
    Name: 'Ad',
    Surname: 'Soyad',
    repeatedPassword: 'Şifreyi Tekrarla',
    'Academic ID': 'Akademik ID',
    'academic title list': 'Akademik Ünvan',
    chooseFaculty: 'Fakülte Seçin',
    chooseDepartment: 'Bölüm Seçin',

    // project types
    All: 'Hepsi',
    article: 'makale',
    project: 'proje',
    'conference paper': 'konferans makalesi',
    'book chapter': 'kitap bölümü',

    //academic titles
    'Prof. Dr.': 'Prof. Dr.',
    'Dr.': 'Dr.',

    'Assoc. Prof. Dr.': 'Doç. Dr.',
    'Assist. Prof. Dr.': 'Dr. Öğr. Üyesi',
    'Inst.': 'Öğr. Gör.',
    'Res. Assist.': 'Arş. Gör.',

    //departments
    'computer engineering': 'bilgisayar mühendislik',

    //pages
    listOfAccounts: 'hesaplar listesi',
    pendingAccounts: 'bekleyen hesaplar',
    createReport: 'rapor oluştur',
    creatingReport: 'Rapor Oluşturma',
    searchNoun: 'arama',
    downloadReport: 'Tüm Sonuçların Raporunu İndir',

    studyField: 'Çalışma Alanı',
    unverifiedModalHeader: 'E-posta doğrulanmadı.',
    unverifiedModalBody:
      'Yöneticinin kayıt isteğinizi görmesi ve onaylaması için e-postanızı doğrulamanız gerekir.',
    unverifiedModalFooter: 'Yeni bir doğrulama bağlantısı gönderilsin mi?',
    pendingModalHeader: 'Hesabınız henüz yönetici tarafından onaylanmadı.',
    pendingModalBody: 'Kaydolma talebiniz onay için yöneticiye gönderildi.',
    rejectedModalHeader: 'Hesap talebiniz reddedildi.',
    rejectedModalBody: 'Doğru bilgilerle tekrar kayıt olabilirsiniz.',
    rejectedModalFooter: 'Ya da itiraz talebi gönderebilirsiniz.',
    pendingModalFooter: 'Onay için beklemeniz gerekiyor.',
    successfullRegisterationHeader: 'Hesabınızı doğrulayın',
    successfullRegisterationBody:
      'E-postanıza bir doğrulama bağlantısı gönderildi.',
    successfullRegisterationFooter:
      'Hesabınızı doğrulamak için e-postanızı kontrol edin.',
    successfullVerificationHeader: 'e-postanız doğrulandı.',
    successfullVerificationBody: 'Hesabınız onay için yöneticiye gönderildi.',
    DateBefore: 'Yayın Tarihi den',
    DateAfter: 'Yayın Tarihi a kadar',
    moreThanCitations: 'Daha Fazla Alıntı',
    lessThanCitations: 'Daha az alıntı',
    delete: 'Sil',
    deleteModalHeader: 'Akademik Ürünü Sil',
    deleteModalBody: 'Bu akademik ürünü silmek istediğinizden emin misiniz?',
    deleteModalFooter: 'Bu işlem geri alınamaz!',
    ORCİd: 'ORCİd',
    indexType: 'İndeks Türü',
  },
}
