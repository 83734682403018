import * as React from "react";

const SvgCitationsicon = (props) => (
  <svg
    data-name="Citations icon"
    xmlns="http://www.w3.org/2000/svg"
    width={18.074}
    height={18.074}
    {...props}
  >
    <path data-name="Path 254" d="M0 0h18.074v18.074H0Z" fill="none" />
    <path
      data-name="Path 255"
      d="M2.259 3.765H.753v12.05a1.511 1.511 0 0 0 1.506 1.506h12.05v-1.506H2.259ZM15.815.753H5.272a1.511 1.511 0 0 0-1.507 1.506v10.543a1.511 1.511 0 0 0 1.506 1.506h10.544a1.511 1.511 0 0 0 1.506-1.506V2.259A1.511 1.511 0 0 0 15.815.753Zm0 12.049H5.272V2.259h10.543ZM9.79 11.296h1.506a1.506 1.506 0 0 0 1.506-1.506V8.66a1.128 1.128 0 0 0-1.13-1.13 1.128 1.128 0 0 0 1.13-1.13V5.272a1.506 1.506 0 0 0-1.506-1.506H9.79a1.506 1.506 0 0 0-1.506 1.506v1.13a1.128 1.128 0 0 0 1.13 1.13 1.128 1.128 0 0 0-1.13 1.13v1.13a1.506 1.506 0 0 0 1.506 1.504Zm0-6.025h1.506v1.507H9.79Zm0 3.012h1.506v1.506H9.79Z"
      fill="#134383"
    />
  </svg>
);

export default SvgCitationsicon;
